
export default {
  name: "partners",
  components: {},
  data() {
    return {
      profileDisabled:false,
      contactDisabled:false,
      messageDisabled:false,
      addressDisabled:false,
      openNewAddress:false,
      deliveryAddressCheck:[{}],
      professional: localStorage.getItem('professional'),
      deliveryAddress: [
      {
        city:'Украина',
        country:'г. Киев',
        street: 'проспект Победы',
        homeNum:'77А',
        room:'кв 15'
      },
      {
        city:'Украина',
        country:'г. Житомир',
        street: 'Ул. И. Кочерги',
        homeNum:'13/1',
        room:''
      }
    ],
      payload: {
        name: '',
        surname: '',
        message:'',
        patroname: '',
        phone: '',
        telegram: '',
        viber: '',
        email:'',
        city:'',
        country:'',
        address:{
          city:'',
          country:'',
          street: '',
          homeNum:'',
          room:''
        },
        street: '',
        room:'',
        homeNum:''
      },
    };
  },
  mounted(){
    this.deliveryAddressCheck = this.deliveryAddress[0]
  },
  methods: {
    getPureText(str){
      return str = str.replace(/,\s*$/, "");
    },
    addNewAddress(){
      if(this.payload.address.city!=='' && this.payload.address.country!==''){
        this.deliveryAddress.push(this.payload.address)
      }
    },
    removeAddress(id){
      console.log(id)
      this.deliveryAddress.splice(id, 1);
    }
  }

};
